new AirDatepicker("#date-form-report1", {
    isMobile: !!(window.innerWidth <= 680),
    autoClose: !!(window.innerWidth <= 680),
});
new AirDatepicker("#date-form-report2", {
    isMobile: !!(window.innerWidth <= 680),
    autoClose: !!(window.innerWidth <= 680),
});
new AirDatepicker("#date-form-report3", {
    isMobile: !!(window.innerWidth <= 680),
    autoClose: !!(window.innerWidth <= 680),
});
new AirDatepicker("#date-form-report4", {
    isMobile: !!(window.innerWidth <= 680),
    autoClose: !!(window.innerWidth <= 680),
});
